import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FiArrowUpRight, FiArrowRight } from "react-icons/fi"

import {
  Container,
  Wrapper,
  Row,
  Box,
  Button,
  FlexButtons,
} from "../components/util"
import Layout from "../components/layout"
import Image from "../components/image"
import Seo from "../components/seo"

const WorkList = styled(Row)`
  --item-gap: 30px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 2) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`

const WorkCard = styled(Box)`
  padding: 50px;
  border-radius: 20px;
  overflow: hidden;

  @media (max-width: ${props => props.theme.breakpoints.desktop}) {
    padding: 40px;
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 15px 15px 25px 15px;
  }

  &:hover {
    @media (min-width: ${props => props.theme.breakpoints.mobile}) {
      .gatsby-image-wrapper {
        filter: blur(4px);
      }

      .work-overlay {
        opacity: 1;
        pointer-events: all;
      }

      .work-content {
        opacity: 1;
        top: 0;
        pointer-events: all;
      }
    }
  }

  .gatsby-image-wrapper {
    border-radius: 10px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
    transition: all 0.25s ease-in-out;
  }

  .work-overlay {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.25s ease-in-out;

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      display: none;
    }
  }

  .work-content {
    padding: 30px;
    position: absolute;
    width: 100%;
    top: 30px;
    left: 0;
    bottom: 0;
    opacity: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;
    transition: all 0.25s ease-in-out;

    @media (max-width: ${props => props.theme.breakpoints.mobile}) {
      position: relative;
      pointer-events: all;
      opacity: 1;
      padding: 0;
      margin-bottom: 25px;

      a {
        padding-left: 0;
        padding-right: 0;
        margin-top: 10px;
        background: none;

        &.white {
          color: #fff;
          * {
            color: #fff;
          }
        }
      }
    }

    a {
      width: fit-content;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
`

const Home = props => {
  const workData = useStaticQuery(graphql`
    query {
      allContentfulWork(sort: { order: DESC, fields: completedDate }) {
        edges {
          node {
            client
            excerpt {
              internal {
                content
              }
            }
            url
            colour
            completedDate
            mainImage {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            tags
            website
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo pathname={props.location.pathname} />
      <Container>
        <Wrapper>
          <Box gap={7} className="-textCenter -center -whiteOut">
            <Image
              alt="James Barber"
              filename="mypic.png"
              centerImage
              width={150}
            />
            <h1 className="-fontLarge -fontHeavy">James Barber</h1>
            <h3>
              Experienced product, web & graphic designer based in Wellington.
            </h3>
            <br />
            <h3>
              <a href="tel:0273444549" css={{ color: "#FAA21A!important" }}>
                0273444549
              </a>
              {"    "}•{"    "}
              <a
                href="mailto:jamesharriesbarber@gmail.com"
                css={{ color: "#FAA21A!important" }}
              >
                jamesharriesbarber@gmail.com
              </a>
              {"    "}•{"    "}
              <a
                href="https://www.linkedin.com/in/jamesharriesbarber"
                css={{ color: "#FAA21A!important" }}
              >
                LinkedIn
              </a>
            </h3>
          </Box>
        </Wrapper>
      </Container>
      <Container id="work">
        <Wrapper gap="0" noPaddingBottom>
          <WorkList isWrap justify="flex-start">
            {workData.allContentfulWork.edges.map((edge, i) => {
              return (
                <WorkCard bg={edge.node.colour} key={i}>
                  <GatsbyImage image={edge.node.mainImage.gatsbyImageData} />
                  <div className="work-overlay"></div>
                  <div className="work-content">
                    <Box gap={20}>
                      <Box gap={10}>
                        {edge.node.client && <h1>{edge.node.client}</h1>}
                        {edge.node.excerpt && (
                          <h3>{edge.node.excerpt.internal.content}</h3>
                        )}
                      </Box>
                      {/* {edge.node.tags && (
                        <Row justify="flex-start" noBreak isWrap>
                          {edge.node.tags.map(i => {
                            return (
                              <div
                                key={i}
                                className="badge -white"
                                css={{
                                  marginRight: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {i}
                              </div>
                            )
                          })}
                        </Row>
                      )} */}
                    </Box>
                    <Row justify="flex-start" gap={15}>
                      {edge.node.website && (
                        <Button
                          className="-lg"
                          css={{
                            backgroundColor: edge.node.colour,
                            color:
                              edge.node.client === "FlexiTime" ||
                              edge.node.client === "Abuild Consulting Engineers"
                                ? "#000"
                                : "",
                          }}
                          to={edge.node.website}
                          externalLink
                          atag
                        >
                          Visit Website{" "}
                          <FiArrowUpRight
                            css={{ marginLeft: "5px", top: "2px" }}
                          />
                        </Button>
                      )}
                      {edge.node.url && (
                        <Button className="-lg white" to={edge.node.url}>
                          Learn More{" "}
                          <FiArrowRight
                            css={{ marginLeft: "5px", top: "2px" }}
                          />
                        </Button>
                      )}
                    </Row>
                  </div>
                </WorkCard>
              )
            })}
          </WorkList>
        </Wrapper>
      </Container>
      <Container id="contact">
        <Wrapper gap={120} className="-textCenter -whiteOut">
          <Box gap={10}>
            <h2 className="-fontLarge -fontHeavy">Let's talk</h2>
            <h3 className="-fontNormal">
              <a href="tel:0273444549" css={{ color: "#FAA21A!important" }}>
                0273444549
              </a>
              {"    "}•{"    "}
              <a
                href="mailto:jamesharriesbarber@gmail.com"
                css={{ color: "#FAA21A!important" }}
              >
                jamesharriesbarber@gmail.com
              </a>
              {"    "}•{"    "}
              <a
                href="https://www.linkedin.com/in/jamesharriesbarber"
                css={{ color: "#FAA21A!important" }}
              >
                LinkedIn
              </a>
            </h3>
          </Box>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default Home
