import React from "react";
import { StaticQuery, graphql } from "gatsby";
// import Img from "gatsby-image";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              gatsbyImageData(webpOptions: { quality: 100 }, layout: FULL_WIDTH)
              fluid {
                originalName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.allImageSharp.edges.find((n) => {
        return n.node.fluid.originalName.includes(props.filename);
      });
      if (!image) {
        return null;
      }

      return (
        <GatsbyImage
          key={props.key}
          alt={props.alt}
          title={props.alt}
          image={image.node.gatsbyImageData}
          css={{ width: props.width, maxWidth: props.maxWidth }}
          className={`${props.addShadow ? "-addShadow" : ""} ${
            props.rounded ? "-rounded" : ""
          } ${props.centerImage ? "-center" : ""}`}
        />
      );
    }}
  />
);

export default Image;
